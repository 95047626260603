'use client';

import { ButtonColor } from '@/newComponents/Button/Button.utils';
import SvgIcon from '@/newComponents/SvgIcon/SvgIcon';
import Divider from '@/newComponents/Divider/Divider';
import Typography from '@/newComponents/Typography/Typography';

import React, { AriaAttributes, ReactNode, SyntheticEvent, useMemo } from 'react';

import { Color } from '@/global/style/variables';
import { FontWeight } from '@/constants';
import HtmlFromCMS from '@/global/utils/HtmlFromCMS';
import { HallonColor } from '@/global/utils/styleTypes';

import { Border, Container, IconWrapper, Inner, NoticeFieldButton, NoticeText } from './NoticeField.styled';
import { NoticeFieldPreset } from './NoticeField.types';

const getNoticeFieldColorSet = (preset: NoticeFieldPreset) => {
  switch (preset) {
    case NoticeFieldPreset.Success:
      return {
        topBorder: Color.Forest,
        background: Color.Nyanza,
        textcolor: Color.Forest,
      };
    case NoticeFieldPreset.Warning:
      return {
        topBorder: Color.Hallon1,
        background: Color.Sand,
        textcolor: Color.Brown,
      };
    case NoticeFieldPreset.Error:
      return {
        topBorder: Color.Hallon1,
        background: Color.Rose,
        textcolor: Color.Hallon1,
      };
    case NoticeFieldPreset.Notice:
    default:
      return {
        topBorder: Color.Blue,
        background: Color.Sky,
        textcolor: Color.Blue,
      };
  }
};

const getNoticeIcon = (preset: NoticeFieldPreset) => {
  switch (preset) {
    case NoticeFieldPreset.Success:
      return <SvgIcon icon="check-circle" size="small" fill={Color.Nyanza} checkmarkColor={Color.Forest} />;
    case NoticeFieldPreset.Warning:
      return <SvgIcon icon="alert-triangle" size="small" />;
    case NoticeFieldPreset.Error:
      return <SvgIcon icon="error" size="small" />;
    case NoticeFieldPreset.Notice:
    default:
      return <SvgIcon icon="alert-circle" size="small" />;
  }
};

export type NoticeFieldProps = {
  className?: string;
  ['data-testid']: string;
  preset: NoticeFieldPreset;
  header?: ReactNode;
  text?: ReactNode;
  buttonText?: string;
  buttonOnClick?: (e: SyntheticEvent) => void;
  buttonHref?: string;
  useMaxLength?: boolean;
  fullWidthText?: boolean;
  fullWidth?: boolean;
  customBackgroundColor?: HallonColor | undefined;
  customTextColor?: HallonColor | undefined;
  customIcon?: React.ReactNode | null;
  customPadding?: string;
  showBorder?: boolean;
  bleed?: boolean;
  ariaLive?: AriaAttributes['aria-live'];
};

const NoticeField = (props: NoticeFieldProps) => {
  const {
    className,
    'data-testid': dataTestId,
    preset,
    header,
    text,
    buttonText,
    buttonOnClick,
    buttonHref,
    useMaxLength = false,
    fullWidthText = false,
    fullWidth = false,
    customBackgroundColor,
    customTextColor,
    customIcon,
    customPadding = undefined,
    showBorder = true,
    bleed = false,
    ariaLive,
  } = props;

  const activePreset = getNoticeFieldColorSet(preset);

  const background = preset ? activePreset?.background : customBackgroundColor;
  const textColor = preset ? activePreset?.textcolor : customTextColor;
  const topBorderColor = activePreset?.topBorder;

  // useToastNotice in app router is causing infinite re-renders without this memo
  const memoizedText = useMemo(() => {
    if (typeof text === 'string') {
      return <HtmlFromCMS html={text} />;
    }

    return text;
  }, [text]);

  if (!text && !header) return null;

  return (
    <Container
      $background={background}
      $fullWidth={fullWidth}
      $bleed={bleed}
      className={className}
      data-testid={dataTestId}
      aria-live={ariaLive}
      role="alert"
    >
      {showBorder && <Border $borderColor={topBorderColor} />}
      <Inner $padding={customPadding}>
        <IconWrapper color={textColor}>
          <IconWrapper color={textColor}>{customIcon ?? getNoticeIcon(preset)}</IconWrapper>
        </IconWrapper>

        <NoticeText $useMaxLength={useMaxLength} $fullWidthText={fullWidthText} $textColor={textColor}>
          {header && <Typography color={textColor} text={header} fontWeight={FontWeight.SemiBold} />}
          {memoizedText && <Typography color={textColor}>{memoizedText}</Typography>}
        </NoticeText>
      </Inner>

      {buttonText && (
        <>
          <NoticeFieldButton
            $preset={preset}
            label={buttonText}
            onClick={buttonOnClick}
            color={ButtonColor.Secondary}
            centered
            type="button"
            data-testid="notice-field-basic-button"
            {...(buttonHref && {
              href: buttonHref,
              asLink: true,
            })}
          />
          <Divider />
        </>
      )}
    </Container>
  );
};

export default NoticeField;
